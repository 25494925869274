@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Redressed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media (max-width: 576px) {
  body {
    width: 100%;
  }
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
header {
  width: 100%;

  box-shadow: 0px 15px 10px -15px #111;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 10;
}
.header .logo img {
  height: 80px;
}

.hero {
  background: url(./img/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 80px);
}
@media (max-width: 576px) {
  .hero {
    background: url(./img/bg-m.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
.hero h1 {
  font-size: 60px;
  font-family: "Bebas Neue", cursive;
  color: white;
  text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
}

.hero p {
  font-size: 30px;
  font-family: "Bebas Neue", cursive;
  color: rgb(221, 221, 221);
  max-width: 700px;
}
@media (max-width: 576px) {
  .hero h1 {
    font-size: 30px;
    width: 300px;
    text-align: center;
  }
}
.desktop-menu ul li {
  display: inline-block;
  list-style: none;
  font-size: 20px;
  padding: 20px;
}
.desktop-menu ul li a {
  font-weight: bold;
  text-decoration: none;
  color: dimgrey;
  text-transform: uppercase;
}
.desktop-menu ul li a:hover {
  color: tomato;
}

@media (max-width: 576px) {
  .desktop-menu ul li {
    display: inline-block;
    list-style: none;
    font-size: 14px;
    padding: 10px;
  }
}
/*----info Section-------*/

.info {
  width: 100%;
  background-color: #e7e3e3;
}
.info .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.info-item:nth-child(2) {
  background-color: rgb(15, 109, 146);
}
.info-item:nth-child(3) {
  background-color: rgb(56, 238, 65);
}
.info-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  height: 100px;
  font-size: 22px;
  padding: 30px 50px;
  margin: 30px 0;
  color: white;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 1px;
  border-radius: 10px;
  background-color: rgb(247, 132, 56);
}

@media (max-width: 576px) {
  .info .container {
    flex-direction: column;
    justify-content: center;
  }
  .info-item {
    flex-direction: column;
    justify-content: center;
  }
}

/*------------Grid Section ----------*/
.grid {
  width: 100%;
  padding: 150px 0px;
  background-color: tomato;
}

.grid .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.grid-item {
  width: 250px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}
.grid-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.grid-item img {
  width: 100%;
  height: 200px;
}
.grid-item p {
  text-align: center;
  color: rgb(196, 96, 78);
  font-family: Georgia, "Times New Roman", Times, serif;
}
.grid h3 {
  font-family: "Bebas Neue", cursive;
  letter-spacing: 1px;
  font-size: 22px;
  color: tomato;
  padding: 10px 0;
}

/*------------Quete Section ----------*/
.quete {
  width: 100%;
  background-color: rgb(108, 108, 248);
}
.quete .container {
  text-align: center;
  padding: 200px 0px;
}

@media (max-width: 576px) {
  .quete .container {
    width: 100%;
    text-align: center;
    padding: 0px;
  }
}
.quete .container .icon {
  color: rgb(255, 255, 255);
  font-size: 40px;
}
.quete .container h2 {
  font-family: "Berkshire Swash", cursive;
  color: white;
  font-size: 40px;
  margin-bottom: 30px;
}
.qute-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .qute-item {
    flex-direction: column;
    justify-content: center;
  }
  .qute-item-people {
    display: flex;
    justify-content: center;
  }
  .qute-item-people img {
    width: 200px;
    height: 200px;
  }
}
.qute-item-people {
  width: 20%;
}
.qute-item-people img {
  width: 200px;
  background-color: white;
  border-radius: 50%;
}
.qute-item-text {
  width: 80%;
}
.qute-item-text p {
  font-size: 30px;
  color: rgb(244, 247, 247);
  font-family: "Redressed", cursive;
}
.rting-icon {
  font-size: 20px;
  color: yellow;
}

/*------------Lrand logo Section ----------*/
.brand-item .container {
  text-align: center;
  overflow: auto;
}
.brand-item .container h2 {
  font-family: "Berkshire Swash", cursive;
  color: tomato;
  text-decoration: tomato underline 3px;
  font-size: 40px;
  margin: 10px;
}
.brand-logo {
  width: 150px;
  height: 100px;
}
@media (max-width: 576px) {
  .brand-logo {
    width: 80%;
    height: auto;
  }
}

/*------------News letter Section ----------*/
.lead-box {
  text-align: center;
  background: url(./img/newsletter-bg.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 20px 0px;
}
input[type="text"],
input[type="submit"] {
  width: 30%;
  padding: 12px;
  margin: 8px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
@media (max-width: 576px) {
  input[type="text"],
  input[type="submit"] {
    width: 70%;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
  }
}
input[type="submit"] {
  background-color: rgb(14, 112, 224);
  color: white;
  border: none;
}

input[type="submit"]:hover {
  opacity: 0.8;
}
.lead-box .container h2 {
  font-family: "Berkshire Swash", cursive;
  color: rgb(14, 112, 224);
  font-size: 40px;
  margin: 10px;
}
.lead-box .container p {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
}
footer {
  background-color: rgb(48, 48, 48);
}
footer .container {
  width: 100%;

  color: rgb(167, 167, 167);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

/*-----  About Page ------*/

.about-us {
  width: 100%;
  height: auto;
  margin-top: 50px;
}
.about-us .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-us .container h2 {
  font-size: 40px;
  color: rgb(42, 42, 231);
  font-family: "Berkshire Swash", cursive;
}
.about-us .container p {
  padding: 50px 0;
  font-size: 20px;
  margin-right: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.about-us-text {
  width: 50%;
}
.about-us-img {
  width: 50%;
}
.about-us-img img {
  width: 100%;
}
@media (max-width: 576px) {
  .about-us .container {
    flex-direction: column-reverse;
    height: auto;
  }
  .about-us-img {
    width: 100%;
  }
  .about-us-text {
    padding: 80px;
    width: 100%;
    height: auto;
  }
}

/**********Service Page***************/
.service .container {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.service-header {
  width: 100%;
  background-color: rgb(34, 34, 83);
}
.service-header h2 {
  color: white;
  text-align: center;
  padding: 50px 0;
  font-size: 40px;
  font-family: "Berkshire Swash", cursive;
}
.service-items {
  display: flex;
  justify-content: center;
  align-self: center;
}
.service-items img {
  width: 200px;

  border-radius: 20px;
  margin: 10px;
  transition: all 0.5s ease;
}
.service-items img:hover {
  width: 220px;
  transform: rotate(-10deg);
}
.service-items h3 {
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .service-header h2 {
    font-size: 20px;
  }
  .service-items img {
    width: 100px;
    height: 100px;
  }
  .service-items h3 {
    font-size: 15px;
  }
}
